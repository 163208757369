<template>
  <div>
    <!-- Input -->
    <vs-input
      @change="change"
      @input="input"
      @blur="blur"
      :label="label"
      :value="maskedValue"
      :class="customClass"
      :disabled="disabled"
      :readonly="readonly"
    />
  </div>
</template>

<script>
import Vue from "vue";
import defaultOptions from "./options";
import NumberFormat from "./number-format";

export default {
  name: "vue-number",
  props: {
    value: {
      type: [String, Number],
    },
    nullValue: {
      type: [Number, String],
      default: defaultOptions.nullValue,
    },
    customClass: {
      type: String,
      default: "w-full",
    },
    masked: Boolean,
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reverseFill: {
      type: Boolean,
      default: defaultOptions.reverseFill,
    },
    prefill: {
      type: Boolean,
      default: defaultOptions.prefill,
    },
    precision: {
      type: Number,
      default: () => defaultOptions.precision,
    },
    minimumFractionDigits: {
      type: Number,
      default: () => defaultOptions.minimumFractionDigits,
    },
    decimal: {
      type: String,
      default: () => defaultOptions.decimal,
    },
    min: {
      type: Number,
      default: () => defaultOptions.min,
    },
    max: {
      type: Number,
      default: () => defaultOptions.max,
    },
    separator: {
      type: String,
      default: () => defaultOptions.separator,
    },
    prefix: {
      type: String,
      default: () => defaultOptions.prefix,
    },
    suffix: {
      type: String,
      default: () => defaultOptions.suffix,
    },
    label: {
      type: String,
      default: () => defaultOptions.label,
    },
  },
  // emits: ['update:value', 'input:value'],
  data() {
    return {
      maskedValue: 0,
      canEmit: false,
      unmaskedValue: "",
      config: Vue.util.extend({}, this),
      // formatNumber: 0,
    };
  },
  watch: {
    value: {
      handler: function (newValue, oldValue) {
        console.log("new", newValue);
        console.log("old", oldValue);
        console.log("old", this.maskedValue);
        let formatNumber = new NumberFormat(defaultOptions);
        const number = formatNumber.format(newValue);
        console.log("the number", number);
        if (number !== this.maskedValue) {
          this.maskedValue = number;
        }
      },
    },
  },
  computed: {
    emittedValue() {
      console.log(defaultOptions);
      if (this.masked) {
        console.log(this.masked);
        let formatNumber = new NumberFormat(defaultOptions);
        return formatNumber.format(this.maskedValue);
      }
      return this.unmaskedValue;
    },
    emittedNumber() {
      console.log(this.maskedValue, "this.emittedNumber");
      let formatNumber = new NumberFormat(defaultOptions);
      return formatNumber.unformat(this.maskedValue);
    },
  },
  mounted() {
    console.log(this.maskedValue, "this.maskedValue");
    console.log(this.canEmit, "this.canEmit");
    console.log(this.value, "this.canEmit");
    this.maskedValue = this.value;
  },
  methods: {
    blur() {
      console.log("blur");
      if (this.canEmit && this.emittedValue !== this.value) {
        this.change();
      }
    },
    change() {
      console.log("blur");
      this.$emit("input", this.emittedValue);
      this.$emit("withoutFormat", this.emittedNumber);
    },
    input() {
      const { target } = event;
      this.maskedValue = target.value;
      console.log(this.maskedValue);
      this.unmaskedValue = target.value;
      this.canEmit = true;
      this.$emit("input", this.emittedValue);
      this.$emit("withoutFormat", this.emittedNumber);
    },
  },
};
</script>
