<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Create Cash Deposit</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Territory</label>
        <multiselect
          class="selectExample"
          v-model="create.territory"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          :disabled="false"
          placeholder="Select teritory"
          track-by="TerritoryID"
          :custom-label="queryTerritory"
          
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{
                `${dt.option.TerritoryCode} ${dt.option.TerritoryName}`
              }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{
                `${dt.option.TerritoryCode} ${dt.option.TerritoryName}`
              }}</span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Deposit Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Date Deposit"
          v-model="date_deposit"
          placeholder="Date Deposit"
          :disabled-dates="{ from: new Date() }"
        ></datepicker>
        <!-- disabled-dates="{from: new Date()}" -->
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Cash Deposit Slip No</label>
        <vs-input class="w-full" v-model="create.deposit_number" />
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Collection Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Invoice Date"
          v-model="date_clearing"
          placeholder="Date Clearing"
          :disabled-dates="{ from: new Date() }"
        ></datepicker>
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Deposit Value</label>
        <!-- <vs-input
          class="w-full"
          v-model="create.amount"
          @keyup="
            create.amount = formatPrice(create.amount ? create.amount : 0)
          "
        /> -->
        <vue-number
          :customClass="`w-full`"
          v-model="create.amount"
          :masked="true"
          :disabled="false"
        ></vue-number>
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Method Management</label>
        <multiselect
          class="selectExample"
          v-model="create.method"
          :options="optionMethod"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
        >
        </multiselect>
      </div>

      <div class="vx-col w-full" v-if="!hideForm">
        <label class="vs-input--label">Customer</label>
        <multiselect
          class="selectExample"
          v-model="create.customer"
          :options="optionCustomer"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="name"
          @search-change="getOptionCustomer"
          :disabled="false"
        >
        </multiselect>
      </div>

      <div class="vx-col w-full" v-if="!hideForm">
        <label class="vs-input--label">{{ this.create.method }} Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Date"
          v-model="date_giro"
          :placeholder="'Date ' + create.method"
          :disabled-dates="{ from: new Date() }"
        ></datepicker>
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Ref Code</label>
        <vs-input class="w-full" v-model="create.ref_code" />
      </div>

      <div class="vx-col w-full" v-if="!hideForm">
        <label class="vs-input--label">Bank</label>
        <multiselect
          class="selectExample"
          v-model="bank"
          :options="optionBank"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
        >
        </multiselect>
      </div>

      <div class="vx-col w-full" v-if="!hideForm">
        <label class="vs-input--label"
          >Number Of {{ this.create.method }}</label
        >
        <vs-input class="w-full" v-model="create.number_of" />
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Operating Unit Bank</label>
        <multiselect
          class="selectExample"
          v-model="create.operatingUnitBank"
          :options="optionOperatingUnitBank"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="AccountName"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{
                dt.option.BankName +
                " " +
                dt.option.AccountName +
                " " +
                dt.option.AccountNumber
              }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{
                dt.option.BankName +
                " " +
                dt.option.AccountName +
                " " +
                dt.option.AccountNumber
              }}</span>
            </div>
          </template>
        </multiselect>
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Note</label>
        <vs-textarea v-model="create.note" />
      </div>

      <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <div class="vx-col sm:w-2/3 w-full">
              <div class="vx-col sm:w-4/5 w-full">
                <input
                  id="fileInput"
                  name="file"
                  class="w-full inputx"
                  type="file"
                  ref="file"
                  multiple="multiple"
                  accept=".jpg, .jpeg, .png, .pdf"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"
        >List Attachment</vs-divider
      >
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <table class="vs-table vs-table--tbody-table">
          <template v-for="(tr, i) in fileAttachment">
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.name"
            >
              <td class="td vs-table--td">{{ tr.name }}</td>
              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.path != ''">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click.stop="downloadFileAwsS3(tr.path)"
                  />
                </vx-tooltip>
              </td>
              <td class="td vs-table--td">
                <template>
                  <vx-tooltip text="Delete">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click.stop="handleDeleteAttachment(i, tr.id)"
                    />
                  </vx-tooltip>
                </template>
              </td>
            </tr>
          </template>
        </table>
      </div>

      <div class="vx-col w-full">
        <br />
        <vs-button class="mb-2" v-on:click="handleSubmit">Create</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import VueNumber from "@/components/vue-number/component.vue";

export default {
  components: {
    Datepicker,
    VueNumber,
  },
  created() {},
  data() {
    return {
      create: {
        customer: null,
        amount: 0,
        deposit_number: "",
        note: "",
        type: 1,
        territory: null,
        date_clearing: null,
        method: "Cash",
        date_deposit: null,
        operatingUnitBank: null,
        number_of: "",
      },
      hideForm: true,
      date_giro: null,
      date_deposit: null,
      date_clearing: null,
      bank: null,
      optionOperatingUnitBank: [],
      optionTerritory: [],
      optionCustomer: [],
      optionBank: [],
      fileAttachment: [],
      optionMethod: ["Cash"], //,'Giro','Cheque','Transfer'],
    };
  },
  methods: {
    initialState() {
      return {
        create: {
          customer: null,
          amount: 0,
          deposit_number: "",
          note: "",
          type: 1,
          territory: null,
          date_clearing: null,
          method: "Cash",
          date_deposit: null,
          operatingUnitBank: null,
          number_of: "",
        },
        hideForm: true,
        date_giro: null,
        date_deposit: null,
        date_clearing: null,
        bank: null,
        optionOperatingUnitBank: [],
        optionTerritory: [],
        optionCustomer: [],
        optionBank: [],
        fileAttachment: [],
        optionMethod: ["Cash"], //,'Giro','Cheque','Transfer'],
      };
    },

    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        this.fileAttachment = [];
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          // console.log(this.file[i])
          // this.uploadData(i);
          this.fileAttachment.push({
            name: this.file[i].name,
            File: this.file[i],
          });
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    uploadAttachmentAfter(i, id) {
      if (this.fileAttachment.length == 0) {
        this.$vs.loading.close();
        this.$router.push("/finance/write-off");
        return;
      }
      if (this.fileAttachment.length < i) {
        this.$vs.loading.close();
        this.$router.push("/finance/write-off");
        return;
      }
      var form = new FormData();
      form.append("id", id);
      form.append("file", this.fileAttachment[i].File);
      this.$http
        .post("/api/v1/write-off/upload-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.uploadAttachmentAfter(++i, id);
          // this.$vs.loading.close();
        })
        .catch((error) => {
          this.uploadAttachmentAfter(++i, id);
        });
      // })
    },

    handleShowAttachment(file) {
      let objectURL;
      // console.log(file)
      objectURL = URL.createObjectURL(file.File);

      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, "_blank").focus();
    },
    handleDeleteAttachment(index) {
      // const deleteAttachment =this.fileAttachment[index]
      this.fileAttachment = this.fileAttachment.filter((r, i) => {
        return i != index;
      });
    },

    formatPrice(angka, prefix = "") {
      return angka
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    checkForm() {
      if (this.create.operatingUnitBank == null) {
        this.$vs.notify({
          title: "Error",
          text: "please select operating unit bank first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      if (this.create.amount == 0) {
        this.$vs.notify({
          title: "Error",
          text: "value deposit must be greater than 0",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      if (this.create.amount == "") {
        this.$vs.notify({
          title: "Error",
          text: "please fill amount",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      if (this.create.ref_code == "") {
        this.$vs.notify({
          title: "Error",
          text: "please fill ref code",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      if (this.create.deposit_number == "") {
        this.$vs.notify({
          title: "Error",
          text: "please fill deposit number",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      if (this.create.territory == null) {
        this.$vs.notify({
          title: "Error",
          text: "please choose territory",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      if (this.date_clearing == null) {
        this.$vs.notify({
          title: "Error",
          text: "please fill Collection Date",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      if (this.fileAttachment.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "please add attachment",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      if (this.create.method == null) {
        this.$vs.notify({
          title: "Error",
          text: "method can't be null",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      if (this.date_deposit == null) {
        this.$vs.notify({
          title: "Error",
          text: "please fill date deposit",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      return true;
    },
    handleSubmit() {
      if (!this.checkForm()) {
        return;
      }
      this.$vs.loading();
      this.create.deposit_value = parseInt(
        this.create.amount.toString().replace(/,/g, "")
      );
      this.create.territory_id = this.create.territory.TerritoryID
        ? this.create.territory.TerritoryID
        : this.create.territory.TerritoryID;
      if (
        this.create.method == "Giro" ||
        this.create.method == "Check" ||
        this.create.method == "Transfer" ||
        this.create.method == "Cheque"
      ) {
        console.log(this.create.customer);
        this.create.customer_code = this.create.customer.code;
        this.create.giro_bank_id = this.bank.ID;
        this.create.giro_bank_name = this.bank.Name;
        this.create.date_giro = moment(this.date_giro).format("DD MMMM YYYY");
      }
      this.create.bank_id = this.create.operatingUnitBank.BankID;
      this.create.bank_name = this.create.operatingUnitBank.BankName;
      this.create.account_name = this.create.operatingUnitBank.AccountName;
      this.create.account_number = this.create.operatingUnitBank.AccountNumber;
      this.create.bank_branch_name = this.create.operatingUnitBank.BranchName;
      this.create.date_payment = moment(this.date_clearing).format(
        "DD MMMM YYYY"
      );
      this.create.date_deposit = moment(this.date_deposit).format(
        "DD MMMM YYYY"
      );
      console.log(this.create);
      var form = new FormData();
      // form.append("id", id);
      form.append("file_length", this.fileAttachment.length);
      this.fileAttachment.map((r, i) => {
        // form.append("file_name[]", r.NameFile);
        form.append("file_" + i, r.File);
      });
      form.append("data", JSON.stringify(this.create));

      this.$http
        .post("/api/v1/cash-bank/store/v2", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error",
            text: "Failed to create",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    getGeneralForm() {
      this.$http
        .get("/api/v1/cash-bank/form-general")
        .then((r) => {
          this.optionTerritory = [
            ...this.$store.state.operatingUnit.filter.territory,
          ];
          if (this.optionTerritory.length > 1){
            this.optionTerritory.shift();
          }
          this.create.territory = this.optionTerritory[0];
          // this.optionTerritory = r.data.territories
          this.optionBank = r.data.bank;
          console.log(this.optionTerritory, "this.optionTerritory");
          this.$vs.loading.close();
          this.optionOperatingUnitBank = r.data.operatingUnitBank;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    queryTerritory({ TerritoryCode, TerritoryName }) {
      return `${TerritoryCode} ${TerritoryName}`;
    },
    getBankForm() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/cash-bank/form-bank/" + this.create.territory.TerritoryID)
        .then((r) => {
          // this.optionTerritory = r.data.territory
          // this.optionTerritory = r.data.territories
          // this.optionBank = r.data.bank
          // console.log(r)
          this.optionOperatingUnitBank = r.data.operatingUnitBank;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          console.log(e);
        });
    },
    getOptionCustomer(query) {
      if (query.length <= 2) {
        return;
      }
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/customer", {
          params: {
            search: query,
            length: 20,
          },
        })
        .then((r) => {
          if (r.code == 200) {
            this.optionCustomer = r.data.records;
            this.optionCustomer.map((r) => {
              if (r.name == this.selected.PersonName) {
                this.create.customer = r;
              }
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Customer option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
          console.log(r);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    console.log("mounted");
    console.log("ini create form");
    this.getGeneralForm();
  },
  computed: {},
  watch: {
    "create.method"() {
      if (this.create.method == "Cash") {
        this.hideForm = true;
      } else {
        this.hideForm = false;
      }
    },
    "create.territory"() {
      if (this.optionTerritory.length > 0) {
        this.getBankForm();
      }
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>
